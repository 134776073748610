<template>
  <b-container fluid>
    <b-modal title="Nueva Deducción" id="my-modal" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark" size="md" hide-footer>
      <!-- <h4 class="px-4 py-4"><b>DEVENGADOS</b></h4> -->
      <form @submit.prevent="saveData">
        <div class="row px-5">
          <div class="col-md-12">
            <div class="mb-4">
              <p>Las deducciones que creas aquí son las que te saldrán al momento de gestionar la nómina de tus empleados.</p>
              <label for=" " class="form-label mt-3">Tipo de deducción</label>
              <b-form-select v-model="item.ListaDeduccionId" required value-field="id" text-field="descripcion" :options="listaDeducciones"></b-form-select>
            </div>
          </div>
        </div>
        <div class="w-100 text-right">
          <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Guardar Deducción</button>
          <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$bvModal.hide('my-modal')">Cancelar</button>
        </div>
      </form>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>DEDUCCIONES</b></h4>
          </template>
          <div class="text-right px-4 mt-3">
            <!--   -->
            <!-- <b-button v-b-modal.my-modal @click="infoForPerson(item)">Ver más</b-button> -->
            <!-- <button v-b-modal.my-modal class="mx-1 btn btn-success"><i class="fas fa-plus"></i>Agregar Deducción</button> -->
          </div>
          <div class="table-responsive px-4 mt-3">
            <table id="datatable_deducciones" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Deducciones</th>
                  <!-- <th>Nombre</th> -->
                  <!-- <th>Opciones</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>{{ item.descripcion }}</td>

                  <!-- <td>{{item.listaDeduccion}}</td> -->
                  <!-- <td>
                    <span class="pointer">
                      
                      <i @click="deleteRow(item.id)" class="fas fa-solid fa-trash px-1 text-danger"></i>
                    </span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../../config/pluginInit";
// const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  data() {
    return {
      listaItems: [{}],
      logoEmpresa: "",
      usuario: {},
      listaDeducciones: [],
      Deducciones: [],
      item: {},
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getListaDeducciones();
      await this.getData();
      core.index();
      window.$("#datatable_deducciones").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async saveData() {
      console.log("saving", this.item);
      try {
        this.item.Estado = true;
        this.item.Eliminado = false;
        this.item.EmpresaId = this.usuario.empresa.id;

        let res = await this.$store.dispatch("hl_post", {
          path: "Deduccion/Deduccion/",
          data: this.item,
        });
        console.log("post empresas...", res);
        if (res) {
          this.getData();
          this.$bvModal.hide("my-modal");
        }

        // else alert("Verificar");
      } catch (error) {
        console.log("err", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Deduccion/GetDeducciones/",
        });
        console.log("lista devengados... ", res);
        if (res.length > 0) {
          // this.listaItems = [];
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
    async getListaDeducciones() {
      try {
        console.log(".....................");
        let res = await this.$store.dispatch("hl_get", {
          path: "ListaDeduccion/GetListaDeducciones",
        });
        console.log("lista GetListaDeducciones... ", res);
        if (res.length > 0) {
          // this.listaDevengados = [];
          this.listaDeducciones = res;
        } else {
          this.listaDeducciones = [];
        }
      } catch (error) {
        this.listaDeducciones = [];
        console.log("err", error);
      }
    },
  },
};
</script>
